const COGNITIVE_CITY_APPLICATION = 'cognitiveCityFrontend';

function create(options = {}) {
  const my = {
    exaptiveClient: options.exaptiveClient,
    cityUuid: options.cityUuid || '',
  };
  const that = {
    getCity,
    getCityConfig,
    getCognitiveCityApplicationConfig,
    setCityConfig,
    setCognitiveCityApplicationConfig,
  };

  return that;

  async function getCity() {
    try {
      const cityInfoResponse = await getCityInfo();
      const cityInfo = cityInfoResponse.data;
      my.cityUuid = cityInfo.uuid;

      const configResponses = await Promise.all([
        getCityConfig(),
        getCognitiveCityApplicationConfig(),
      ]);

      const city = configResponses[0].data;
      const cognitiveCity = configResponses[1].data;
      return {
        cityInfo,
        cityConfig: city,
        cognitiveCityConfig: cognitiveCity,

        // Continue to support deprecated accessors
        city,
        cognitiveCity,
      };
    } catch (e) {
      throw new Error('Error retrieving city configuration.');
    }
  }

  function getCityInfo() {
    return my.exaptiveClient.city.readPublicCity();
  }

  function getCityConfig() {
    return my.exaptiveClient.app.config.readPublicCityConfig({
      uuid: my.cityUuid,
    });
  }

  function getCognitiveCityApplicationConfig() {
    return my.exaptiveClient.app.config.readPublicApplicationConfig({
      uuid: my.cityUuid,
      application: COGNITIVE_CITY_APPLICATION,
    });
  }

  function setCityConfig(data) {
    return my.exaptiveClient.app.config.updateCityConfig({
      uuid: my.cityUuid,
      data,
    });
  }

  function setCognitiveCityApplicationConfig(data) {
    return my.exaptiveClient.app.config.updateApplicationConfig({
      uuid: my.cityUuid,
      application: COGNITIVE_CITY_APPLICATION,
      data,
    });
  }
}

const CityConfig = {
  create,
};

export default CityConfig;
